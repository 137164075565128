import "../assets/styles/index.scss";
import { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import Router from "next/router";
import Scripts from "../components/scripts";
// import { useEffect } from "react";
// import * as FullStory from "@fullstory/browser";
// const { loadIntercom } = require("next-intercom");

const progress = new ProgressBar({
  size: 2,
  color: "#F79B01",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const MyApp = ({ Component, pageProps }) => {
  // useEffect(() => {
  //   FullStory.init({ orgId: "161ECZ" });

  //   loadIntercom({
  //     ssr: false,
  //     initWindow: false,
  //     delay: 5000,
  //   });
  // }, []);

  return (
    <>
      <Component {...pageProps} />
      <Toaster position="bottom-right" reverseOrder={false} />
      <Scripts />
    </>
  );
};

export default MyApp;
